import React from 'react'
import { Link } from "gatsby"
import styles from "./LinkBlocks.module.css"

const LinkBlocks = (props) => {
  return <div className={styles.container}>
    <ul className={styles.list}>
      {props.links.map((link, index) => (
        <li className={styles.listItem} key={index}>
          <Link to={`/${props.base}/${link.url}`} className={styles.link}>{link.text}</Link>
        </li>
      ))
      }
    </ul>
  </div>
}

export default LinkBlocks
